import React, { useState, useEffect } from 'react';
import { useCartStore, useLanguageStore } from '../store';
import { Plant } from '../types';
import { ChevronDown, ChevronUp, ShoppingCart } from 'lucide-react';
import toast, { Toaster } from 'react-hot-toast';
import { supabase } from '../lib/supabase';
import { translations } from '../translations';
import ImageLoader from '../components/ImageLoader';

type SortOption = 'popularity' | 'name';

export default function CatalogPage() {
  const addToCart = useCartStore((state) => state.addItem);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [expandedSizes, setExpandedSizes] = useState<string[]>([]);
  const [plants, setPlants] = useState<Plant[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [sortBy, setSortBy] = useState<SortOption>('popularity');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const language = useLanguageStore((state) => state.language);
  const t = translations[language];

  useEffect(() => {
    const fetchPlants = async () => {
      try {
        setLoading(true);
        setError(null);

        let query = supabase
          .from('plants')
          .select('*, plant_sizes(*)');

        if (sortBy === 'popularity') {
          query = query.order('popularity', { ascending: sortDirection === 'asc' });
        } else if (sortBy === 'name') {
          query = query.order('name', { ascending: true });
        }

        const { data: plantsData, error: plantsError } = await query;

        if (plantsError) throw plantsError;

        const processedPlants = plantsData.map(plant => ({
          ...plant,
          sizes: plant.plant_sizes.sort((a: any, b: any) => {
            const heightA = parseInt(a.height.replace(/[^\d]/g, '')) || 0;
            const heightB = parseInt(b.height.replace(/[^\d]/g, '')) || 0;
            return heightA - heightB;
          })
        }));

        setPlants(processedPlants);
      } catch (error) {
        console.error('Error fetching plants:', error);
        setError(t.catalog.fetchError);
        toast.error(t.catalog.fetchErrorToast);
      } finally {
        setLoading(false);
      }
    };

    fetchPlants();
  }, [sortBy, sortDirection, t]);

  const toggleDescription = (plantId: string) => {
    setExpandedItems(prev =>
      prev.includes(plantId)
        ? prev.filter(id => id !== plantId)
        : [...prev, plantId]
    );
  };

  const toggleSizes = (plantId: string) => {
    setExpandedSizes(prev =>
      prev.includes(plantId)
        ? prev.filter(id => id !== plantId)
        : [...prev, plantId]
    );
  };

  const handleAddToCart = (plant: Plant, selectedSize: { height: string; price: number; stock_quantity?: number }) => {
    addToCart({
      plantId: plant.id,
      plantName: language === 'en' ? plant.name_en || plant.name : plant.name,
      selectedSize,
      quantity: 1,
      imageUrl: plant.image_url
    });
    toast.success(t.catalog.addedToCart, {
      duration: 2000,
      position: 'bottom-right',
    });
  };

  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">{t.catalog.title}</h1>
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">{t.catalog.title}</h1>
        <div className="bg-red-50 dark:bg-red-900/20 border border-red-200 dark:border-red-800 rounded-lg p-4 text-red-700 dark:text-red-300">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <Toaster />
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8">
        <h1 className="text-3xl font-bold text-gray-900 dark:text-white">{t.catalog.title}</h1>
        <div className="flex items-center gap-4">
          <div className="flex items-center space-x-2">
            <label className="text-sm text-gray-600 dark:text-gray-300">{t.catalog.sortBy}</label>
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value as SortOption)}
              className="border border-gray-300 dark:border-gray-600 rounded-md px-3 py-1.5 text-sm focus:ring-primary-500 focus:border-primary-500 bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
            >
              <option value="popularity">{t.catalog.popularity}</option>
              <option value="name">{t.catalog.alphabetically}</option>
            </select>
          </div>
          <button
            onClick={() => setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc')}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 transition-colors"
          >
            {sortDirection === 'asc' ? '↑' : '↓'}
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {plants.map((plant) => (
          <div key={plant.id} className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
            <div className="aspect-square">
              <ImageLoader
                src={plant.image_url}
                alt={language === 'en' ? plant.name_en || plant.name : plant.name}
                className="w-full h-full"
              />
            </div>
            <div className="p-4">
              <h2 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">
                {language === 'en' ? plant.name_en || plant.name : plant.name}
              </h2>
              
              {(language === 'en' ? plant.description_en : plant.description) && (
                <div className="mb-4">
                  <button
                    onClick={() => toggleDescription(plant.id)}
                    className="text-primary-600 dark:text-primary-400 hover:text-primary-700 dark:hover:text-primary-300 flex items-center"
                  >
                    {expandedItems.includes(plant.id) ? (
                      <>
                        <span>{t.catalog.hideDescription}</span>
                        <ChevronUp className="w-4 h-4 ml-1" />
                      </>
                    ) : (
                      <>
                        <span>{t.catalog.showDescription}</span>
                        <ChevronDown className="w-4 h-4 ml-1" />
                      </>
                    )}
                  </button>
                  {expandedItems.includes(plant.id) && (
                    <p className="mt-2 text-gray-600 dark:text-gray-300">
                      {language === 'en' ? plant.description_en : plant.description}
                    </p>
                  )}
                </div>
              )}

              <div className="space-y-2">
                <div className="flex items-center justify-between">
                  <p className="font-medium text-gray-900 dark:text-white">{t.catalog.availableSizes}</p>
                  {plant.sizes.length > 1 && (
                    <button
                      onClick={() => toggleSizes(plant.id)}
                      className="text-primary-600 dark:text-primary-400 hover:text-primary-700 dark:hover:text-primary-300 flex items-center text-sm"
                    >
                      {expandedSizes.includes(plant.id) ? (
                        <>
                          <span>{t.catalog.collapse}</span>
                          <ChevronUp className="w-4 h-4 ml-1" />
                        </>
                      ) : (
                        <>
                          <span>{t.catalog.expandAll}</span>
                          <ChevronDown className="w-4 h-4 ml-1" />
                        </>
                      )}
                    </button>
                  )}
                </div>

                {plant.sizes.length > 0 && (
                  <div className="flex items-center justify-between bg-gray-50 dark:bg-gray-700 p-2 rounded">
                    <div className="flex flex-col">
                      <span className="text-gray-700 dark:text-gray-300">{plant.sizes[0].height}</span>
                      {plant.sizes[0].stock_quantity !== undefined && (
                        <span className="text-sm text-gray-500 dark:text-gray-400">
                          {t.catalog.inStock}: {plant.sizes[0].stock_quantity} {t.catalog.pieces}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center space-x-4">
                      <span className="font-semibold text-gray-900 dark:text-white">{plant.sizes[0].price} zł</span>
                      <button
                        onClick={() => handleAddToCart(plant, plant.sizes[0])}
                        className="bg-primary-600 text-white p-2 rounded-lg hover:bg-primary-700 transition-colors"
                      >
                        <ShoppingCart className="w-4 h-4" />
                      </button>
                    </div>
                  </div>
                )}

                {expandedSizes.includes(plant.id) && (
                  <div className="space-y-2">
                    {plant.sizes.slice(1).map((size) => (
                      <div
                        key={size.height}
                        className="flex items-center justify-between bg-gray-50 dark:bg-gray-700 p-2 rounded"
                      >
                        <div className="flex flex-col">
                          <span className="text-gray-700 dark:text-gray-300">{size.height}</span>
                          {size.stock_quantity !== undefined && (
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                              {t.catalog.inStock}: {size.stock_quantity} {t.catalog.pieces}
                            </span>
                          )}
                        </div>
                        <div className="flex items-center space-x-4">
                          <span className="font-semibold text-gray-900 dark:text-white">{size.price} zł</span>
                          <button
                            onClick={() => handleAddToCart(plant, size)}
                            className="bg-primary-600 text-white p-2 rounded-lg hover:bg-primary-700 transition-colors"
                          >
                            <ShoppingCart className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}