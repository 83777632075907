import React from 'react';
import { MapPin, Phone } from 'lucide-react';
import Map from './Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTree } from '@fortawesome/free-solid-svg-icons';
import { useLanguageStore } from '../store';
import { translations } from '../translations';

export default function Footer() {
  const language = useLanguageStore((state) => state.language);
  const t = translations[language];

  return (
    <footer className="bg-primary-900 dark:bg-gray-900 text-white transition-colors duration-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {/* Contact */}
          <div>
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FontAwesomeIcon icon={faTree} className="text-primary-300 h-5 w-5 mr-2" />
              {t.footer.contact}
            </h3>
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <MapPin className="w-5 h-5" />
                <span>Białogórne 5, 96-230 Biała Rawska</span>
              </div>
              <div className="flex flex-col space-y-2">
                <div className="flex items-center space-x-2">
                  <Phone className="w-5 h-5" />
                  <a href="tel:696022651" className="hover:text-primary-300">696 022 651</a>
                </div>
                <div className="flex items-center space-x-2">
                  <Phone className="w-5 h-5" />
                  <a href="tel:725285736" className="hover:text-primary-300">725 285 736</a>
                </div>
              </div>
            </div>
          </div>

          {/* Opening Hours */}
          <div>
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FontAwesomeIcon icon={faTree} className="text-primary-300 h-5 w-5 mr-2" />
              {t.footer.openingHours.title}
            </h3>
            <div className="space-y-2">
              <p>{t.footer.openingHours.weekdays}</p>
              <p>{t.footer.openingHours.saturday}</p>
              <p>{t.footer.openingHours.sunday}</p>
            </div>
          </div>

          {/* Map */}
          <div className="md:col-span-2 lg:col-span-1">
            <h3 className="text-xl font-semibold mb-4 flex items-center">
              <FontAwesomeIcon icon={faTree} className="text-primary-300 h-5 w-5 mr-2" />
              {t.footer.location}
            </h3>
            <div className="h-48 bg-primary-800 dark:bg-gray-800 rounded-lg overflow-hidden">
              <Map />
            </div>
          </div>
        </div>

        <div className="mt-8 pt-8 border-t border-primary-800 dark:border-gray-700 text-center text-sm">
          <div className="flex items-center justify-center mb-2">
            <FontAwesomeIcon icon={faTree} className="text-primary-300 h-4 w-4 mr-2" />
            <span>Iglak Mierzejewski</span>
          </div>
          <p>&copy; {new Date().getFullYear()} Iglak Mierzejewski. {t.footer.rights}.</p>
        </div>
      </div>
    </footer>
  );
}