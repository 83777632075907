import React from 'react';
import { Link } from 'react-router-dom';
import { useCartStore, useLanguageStore } from '../store';
import { ShoppingCart, Menu, X } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTree } from '@fortawesome/free-solid-svg-icons';
import { translations } from '../translations';
import LanguageSwitcher from './LanguageSwitcher';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const cartItems = useCartStore((state) => state.items);
  const language = useLanguageStore((state) => state.language);
  const t = translations[language];

  return (
    <nav className="bg-white dark:bg-gray-800 shadow-lg transition-colors duration-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0 flex items-center">
              <FontAwesomeIcon icon={faTree} className="text-primary-600 dark:text-primary-400 h-6 w-6 mr-2" />
              <span className="text-primary-600 dark:text-primary-400 text-xl font-bold">Iglak Mierzejewski</span>
            </Link>
          </div>

          {/* Desktop Menu */}
          <div className="hidden sm:flex sm:items-center sm:space-x-8">
            <LanguageSwitcher />
            <Link to="/" className="text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400">
              {t.nav.home}
            </Link>
            <Link to="/katalog" className="text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400">
              {t.nav.catalog}
            </Link>
            <Link to="/galeria" className="text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400">
              {t.nav.gallery}
            </Link>
            <Link to="/koszyk" className="text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 relative">
              <ShoppingCart className="w-6 h-6" />
              {cartItems.length > 0 && (
                <span className="absolute -top-2 -right-2 bg-primary-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                  {cartItems.length}
                </span>
              )}
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="sm:hidden flex items-center">
            <LanguageSwitcher />
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="ml-4 text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400"
            >
              {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="sm:hidden fixed inset-0 z-50 bg-gray-900/50 backdrop-blur-sm">
          <div className="bg-white dark:bg-gray-800 h-auto w-full shadow-xl">
            <div className="flex justify-between items-center px-4 py-3 border-b dark:border-gray-700">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faTree} className="text-primary-600 dark:text-primary-400 h-5 w-5 mr-2" />
                <span className="text-primary-600 dark:text-primary-400 font-bold">Iglak Mierzejewski</span>
              </div>
              <button
                onClick={() => setIsMenuOpen(false)}
                className="text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <div className="pt-2 pb-3 space-y-1 px-4">
              <Link
                to="/"
                className="block px-3 py-2 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400"
                onClick={() => setIsMenuOpen(false)}
              >
                {t.nav.home}
              </Link>
              <Link
                to="/katalog"
                className="block px-3 py-2 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400"
                onClick={() => setIsMenuOpen(false)}
              >
                {t.nav.catalog}
              </Link>
              <Link
                to="/galeria"
                className="block px-3 py-2 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400"
                onClick={() => setIsMenuOpen(false)}
              >
                {t.nav.gallery}
              </Link>
              <Link
                to="/koszyk"
                className="block px-3 py-2 text-base font-medium text-gray-700 dark:text-gray-300 hover:text-primary-600 dark:hover:text-primary-400 relative"
                onClick={() => setIsMenuOpen(false)}
              >
                <div className="flex items-center">
                  <span>{t.nav.cart}</span>
                  {cartItems.length > 0 && (
                    <span className="ml-2 bg-primary-600 text-white text-xs rounded-full h-5 w-5 flex items-center justify-center">
                      {cartItems.length}
                    </span>
                  )}
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
}