import React from 'react';

const Map = () => {
  return (
    <div className="w-full h-[300px]">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d314992.422392425!2d20.246475203115942!3d51.917931456031866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47196ad3b5fce2f9%3A0xcffda6378375492d!2sIglakMierzejewski%20szk%C3%B3%CC%82ka%20drzew%20krzew%C3%B3w%20ozdobnych!5e0!3m2!1spl!2spl!4v1729524662543!5m2!1spl!2spl"
        width="100%"
        height="100%"
        style={{ border: '0' }}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="IglakMierzejewski Map"
      />
    </div>
  );
};

export default Map;