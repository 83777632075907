import React, { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';

interface ImageLoaderProps {
  src: string;
  alt: string;
  className?: string;
}

const ImageLoader: React.FC<ImageLoaderProps> = ({ src, alt, className = '' }) => {
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadImage = async () => {
      try {
        setIsLoading(true);
        setError(false);

        // If it's a URL, use it directly
        if (src.startsWith('http')) {
          setImgSrc(src);
          return;
        }

        // If it's a storage path, get the public URL
        const { data: publicUrl } = supabase.storage
          .from('images')
          .getPublicUrl(src.replace(/^\//, ''));

        if (publicUrl) {
          setImgSrc(publicUrl.publicUrl);
        } else {
          throw new Error('Image not found');
        }
      } catch (err) {
        console.error('Error loading image:', err);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    loadImage();
  }, [src]);

  if (isLoading) {
    return (
      <div className={`${className} flex items-center justify-center bg-gray-100 dark:bg-gray-800`}>
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  if (error || !imgSrc) {
    return (
      <div className={`${className} flex items-center justify-center bg-gray-100 dark:bg-gray-800 text-gray-400`}>
        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
        </svg>
      </div>
    );
  }

  return (
    <img
      src={imgSrc}
      alt={alt}
      className={`${className} object-cover`}
      loading="lazy"
    />
  );
};

export default ImageLoader;