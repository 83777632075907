export const translations = {
  pl: {
    nav: {
      home: 'Strona główna',
      catalog: 'Katalog roślin',
      gallery: 'Galeria',
      cart: 'Koszyk'
    },
    home: {
      hero: {
        badge: '20 lat doświadczenia w uprawie roślin',
        title: {
          line1: 'Odkryj magię',
          line2: 'naszej szkółki'
        },
        subtitle: 'Tworzymy zielone przestrzenie z pasją i dbałością o każdy szczegół. Nasze rośliny to gwarancja jakości i piękna Twojego ogrodu.',
        catalogButton: 'Zobacz katalog',
        callButton: 'Zadzwoń do nas'
      },
      features: {
        plants: {
          title: 'Bogaty wybór roślin',
          description: 'Oferujemy szeroki asortyment drzew i krzewów w różnych rozmiarach'
        },
        transport: {
          title: 'Transport',
          description: 'Dowozimy rośliny pod wskazany adres'
        },
        design: {
          title: 'Projektowanie ogrodów',
          description: 'Kompleksowe usługi projektowania i zakładania ogrodów'
        },
        advice: {
          title: 'Doradztwo',
          description: 'Profesjonalne porady ogrodnicze'
        }
      },
      about: {
        title: 'O naszej szkółce',
        description: [
          'Szkółka drzew i krzewów ozdobnych Iglak Mierzejewski to rodzinna firma z 20-letnim doświadczeniem. Specjalizujemy się w uprawie wysokiej jakości roślin ozdobnych na terenie województwa mazowieckiego.',
          'Oferujemy szeroki wybór drzew i krzewów o różnych wysokościach, dostępnych zarówno w gruncie, jak i w doniczkach. Prowadzimy sprzedaż hurtową i detaliczną, zapewniając transport na terenie całej Polski.',
          'Nasze usługi obejmują nie tylko sprzedaż roślin, ale także kompleksowe projektowanie ogrodów, profesjonalne doradztwo oraz usługi sadzenia. W okresie świątecznym prowadzimy również sprzedaż choinek ciętych i w doniczkach.'
        ]
      }
    },
    footer: {
      contact: 'Kontakt',
      openingHours: {
        title: 'Godziny otwarcia',
        weekdays: 'Poniedziałek - Piątek: 7:00 - 19:00',
        saturday: 'Sobota: 7:00 - 18:00',
        sunday: 'Niedziela: 8:00 - 16:00'
      },
      location: 'Lokalizacja',
      rights: 'Wszelkie prawa zastrzeżone'
    },
    catalog: {
      title: 'Katalog roślin',
      sortBy: 'Sortuj według:',
      popularity: 'Popularności',
      alphabetically: 'Alfabetycznie',
      showDescription: 'Pokaż opis',
      hideDescription: 'Ukryj opis',
      availableSizes: 'Dostępne rozmiary:',
      expandAll: 'Rozwiń wszystkie',
      collapse: 'Zwiń',
      addedToCart: 'Dodano do koszyka!',
      height: 'Wysokość',
      inStock: 'Dostępne',
      pieces: 'sztuk',
      fetchError: 'Wystąpił błąd podczas pobierania danych. Spróbuj odświeżyć stronę.',
      fetchErrorToast: 'Nie udało się pobrać listy roślin'
    },
    cart: {
      title: 'Koszyk',
      empty: 'Twój koszyk jest pusty.',
      total: 'Razem:',
      proceedToOrder: 'Przejdź do rezerwacji',
      back: 'Wróć',
      sendOrder: 'Wyślij zamówienie',
      sending: 'Wysyłanie...',
      remove: 'Usuń z koszyka',
      itemRemoved: 'Usunięto z koszyka',
      height: 'Wysokość',
      form: {
        firstName: 'Imię',
        lastName: 'Nazwisko',
        email: 'Email',
        phone: 'Telefon',
        address: 'Adres',
        deliveryDate: 'Data odbioru/dostawy',
        transport: 'Transport',
        planting: 'Sadzenie',
        additionalInfo: 'Dodatkowe informacje',
        orderSuccess: 'Dziękujemy za złożenie zamówienia!',
        orderSuccessDetails: 'Prosimy oczekiwać na telefon od nas w celu potwierdzenia szczegółów zamówienia.',
        redirecting: 'Za chwilę zostaniesz przekierowany na stronę główną...',
        orderError: 'Wystąpił błąd podczas składania zamówienia. Spróbuj ponownie.'
      }
    },
    gallery: {
      title: 'Galeria',
      viewAll: 'Zobacz wszystkie zdjęcia'
    }
  },
  en: {
    nav: {
      home: 'Home',
      catalog: 'Plant Catalog',
      gallery: 'Gallery',
      cart: 'Cart'
    },
    home: {
      hero: {
        badge: '20 years of experience in plant cultivation',
        title: {
          line1: 'Discover the magic',
          line2: 'of our nursery'
        },
        subtitle: 'We create green spaces with passion and attention to detail. Our plants guarantee quality and beauty for your garden.',
        catalogButton: 'View catalog',
        callButton: 'Call us'
      },
      features: {
        plants: {
          title: 'Wide Plant Selection',
          description: 'We offer a broad range of trees and shrubs in various sizes'
        },
        transport: {
          title: 'Transport',
          description: 'We deliver plants to your specified address'
        },
        design: {
          title: 'Garden Design',
          description: 'Comprehensive garden design and landscaping services'
        },
        advice: {
          title: 'Consulting',
          description: 'Professional gardening advice'
        }
      },
      about: {
        title: 'About Our Nursery',
        description: [
          'Iglak Mierzejewski tree and ornamental shrub nursery is a family business with 20 years of experience. We specialize in growing high-quality ornamental plants in the Mazowieckie region.',
          'We offer a wide selection of trees and shrubs of various heights, available both in-ground and in containers. We conduct wholesale and retail sales, providing transport throughout Poland.',
          'Our services include not only plant sales but also comprehensive garden design, professional consulting, and planting services. During the holiday season, we also sell cut and potted Christmas trees.'
        ]
      }
    },
    footer: {
      contact: 'Contact',
      openingHours: {
        title: 'Opening Hours',
        weekdays: 'Monday - Friday: 7:00 AM - 7:00 PM',
        saturday: 'Saturday: 7:00 AM - 6:00 PM',
        sunday: 'Sunday: 8:00 AM - 4:00 PM'
      },
      location: 'Location',
      rights: 'All rights reserved'
    },
    catalog: {
      title: 'Plant Catalog',
      sortBy: 'Sort by:',
      popularity: 'Popularity',
      alphabetically: 'Alphabetically',
      showDescription: 'Show description',
      hideDescription: 'Hide description',
      availableSizes: 'Available sizes:',
      expandAll: 'Expand all',
      collapse: 'Collapse',
      addedToCart: 'Added to cart!',
      height: 'Height',
      inStock: 'Available',
      pieces: 'pcs',
      fetchError: 'An error occurred while fetching data. Please try refreshing the page.',
      fetchErrorToast: 'Failed to load plant list'
    },
    cart: {
      title: 'Cart',
      empty: 'Your cart is empty.',
      total: 'Total:',
      proceedToOrder: 'Proceed to order',
      back: 'Back',
      sendOrder: 'Send order',
      sending: 'Sending...',
      remove: 'Remove from cart',
      itemRemoved: 'Removed from cart',
      height: 'Height',
      form: {
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email',
        phone: 'Phone',
        address: 'Address',
        deliveryDate: 'Delivery/pickup date',
        transport: 'Transport',
        planting: 'Planting',
        additionalInfo: 'Additional information',
        orderSuccess: 'Thank you for your order!',
        orderSuccessDetails: 'Please wait for our call to confirm the order details.',
        redirecting: 'You will be redirected to the homepage shortly...',
        orderError: 'An error occurred while submitting your order. Please try again.'
      }
    },
    gallery: {
      title: 'Gallery',
      viewAll: 'View all photos'
    }
  }
};