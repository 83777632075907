import React, { useState } from 'react';
import { useCartStore, useLanguageStore } from '../store';
import { ReservationForm } from '../types';
import { supabase } from '../lib/supabase';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { CheckCircle, Trash2 } from 'lucide-react';
import ImageLoader from '../components/ImageLoader';
import { translations } from '../translations';

export default function CartPage() {
  const { items, removeItem, updateQuantity, clearCart } = useCartStore();
  const [showForm, setShowForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();
  const language = useLanguageStore((state) => state.language);
  const t = translations[language];
  
  const [formData, setFormData] = useState<ReservationForm>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    deliveryDate: '',
    requiresTransport: false,
    requiresPlanting: false,
    additionalInfo: ''
  });

  // Filter out any invalid items and calculate total
  const validItems = items.filter(item => item.selectedSize && typeof item.selectedSize.price === 'number');
  const total = validItems.reduce((sum, item) => sum + item.selectedSize.price * item.quantity, 0);

  const handleQuantityChange = (plantId: string, height: string, value: string) => {
    const numValue = parseInt(value);
    if (value === '') {
      updateQuantity(plantId, height, 0);
    } else if (!isNaN(numValue) && numValue >= 0) {
      updateQuantity(plantId, height, numValue);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const { data: order, error: orderError } = await supabase
        .from('orders')
        .insert({
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          address: formData.requiresTransport || formData.requiresPlanting ? formData.address : null,
          delivery_date: formData.deliveryDate,
          requires_transport: formData.requiresTransport,
          requires_planting: formData.requiresPlanting,
          additional_info: formData.additionalInfo || null,
          total_amount: total,
          status: 'pending'
        })
        .select()
        .single();

      if (orderError) throw orderError;

      const { data: plants, error: plantsError } = await supabase
        .from('plants')
        .select('id, name, name_en')
        .in('id', validItems.map(item => item.plantId));

      if (plantsError) throw plantsError;

      const plantNames = plants.reduce((acc, plant) => ({
        ...acc,
        [plant.id]: language === 'en' ? plant.name_en || plant.name : plant.name
      }), {});

      const { error: itemsError } = await supabase
        .from('order_items')
        .insert(
          validItems.map(item => ({
            order_id: order.id,
            plant_id: item.plantId,
            plant_name: plantNames[item.plantId] || item.plantName,
            height: item.selectedSize.height,
            price: item.selectedSize.price,
            quantity: item.quantity
          }))
        );

      if (itemsError) throw itemsError;

      clearCart();
      setShowConfirmation(true);
      
      setTimeout(() => {
        navigate('/');
      }, 6000);
    } catch (error) {
      console.error('Error submitting order:', error);
      toast.error(t.cart.form.orderError);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (showConfirmation) {
    return (
      <div className="fixed inset-0 bg-white dark:bg-gray-900 flex items-center justify-center p-4 transition-colors duration-200">
        <div className="text-center">
          <div className="flex justify-center mb-6">
            <CheckCircle className="w-16 h-16 text-green-500" />
          </div>
          <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
            {t.cart.form.orderSuccess}
          </h2>
          <p className="text-gray-600 dark:text-gray-300 mb-2">
            {t.cart.form.orderSuccessDetails}
          </p>
          <p className="text-gray-600 dark:text-gray-300">
            {t.cart.form.redirecting}
          </p>
        </div>
      </div>
    );
  }

  if (validItems.length === 0) {
    return (
      <div className="max-w-7xl mx-auto px-4 py-12">
        <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">{t.cart.title}</h1>
        <p className="text-gray-600 dark:text-gray-300">{t.cart.empty}</p>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <Toaster />
      <h1 className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">{t.cart.title}</h1>
      
      {!showForm ? (
        <>
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6 mb-6">
            {validItems.map((item) => (
              <div key={`${item.plantId}-${item.selectedSize.height}`} className="flex items-center justify-between py-4 border-b dark:border-gray-700 last:border-0">
                <div className="flex items-center space-x-4">
                  <div className="w-16 h-16 rounded-lg overflow-hidden bg-gray-100 dark:bg-gray-700">
                    {item.imageUrl ? (
                      <ImageLoader
                        src={item.imageUrl}
                        alt={item.plantName}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="w-full h-full flex items-center justify-center text-gray-400">
                        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                      </div>
                    )}
                  </div>
                  <div>
                    <h3 className="font-semibold text-gray-900 dark:text-white">{item.plantName}</h3>
                    <p className="text-gray-600 dark:text-gray-300">{t.cart.height}: {item.selectedSize.height}</p>
                    {item.selectedSize.stock_quantity !== undefined && (
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {t.catalog.inStock}: {item.selectedSize.stock_quantity} {t.catalog.pieces}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <input
                    type="number"
                    min="0"
                    value={item.quantity || ''}
                    onChange={(e) => handleQuantityChange(item.plantId, item.selectedSize.height, e.target.value)}
                    className="w-16 px-2 py-1 border rounded dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  />
                  <span className="font-semibold text-gray-900 dark:text-white">{item.selectedSize.price * item.quantity} zł</span>
                  <button
                    onClick={() => removeItem(item.plantId, item.selectedSize.height)}
                    className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300 p-2"
                    title={t.cart.remove}
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))}
            <div className="mt-4 text-right">
              <p className="text-xl font-bold text-gray-900 dark:text-white">{t.cart.total} {total} zł</p>
            </div>
          </div>
          <div className="text-right">
            <button
              onClick={() => setShowForm(true)}
              className="bg-primary-600 text-white px-6 py-2 rounded-lg hover:bg-primary-700 transition-colors"
            >
              {t.cart.proceedToOrder}
            </button>
          </div>
        </>
      ) : (
        <form onSubmit={handleSubmit} className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {t.cart.form.firstName}
              </label>
              <input
                type="text"
                required
                value={formData.firstName}
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {t.cart.form.lastName}
              </label>
              <input
                type="text"
                required
                value={formData.lastName}
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {t.cart.form.email}
              </label>
              <input
                type="email"
                required
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {t.cart.form.phone}
              </label>
              <input
                type="tel"
                required
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="flex items-center space-x-6">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.requiresTransport}
                  onChange={(e) => setFormData({ ...formData, requiresTransport: e.target.checked })}
                  className="mr-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <span className="text-gray-700 dark:text-gray-300">{t.cart.form.transport}</span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={formData.requiresPlanting}
                  onChange={(e) => setFormData({ ...formData, requiresPlanting: e.target.checked })}
                  className="mr-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <span className="text-gray-700 dark:text-gray-300">{t.cart.form.planting}</span>
              </label>
            </div>
            {(formData.requiresTransport || formData.requiresPlanting) && (
              <div className="md:col-span-2">
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {t.cart.form.address}
                </label>
                <input
                  type="text"
                  required
                  value={formData.address}
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                  className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                />
              </div>
            )}
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {t.cart.form.deliveryDate}
              </label>
              <input
                type="date"
                required
                value={formData.deliveryDate}
                onChange={(e) => setFormData({ ...formData, deliveryDate: e.target.value })}
                className="w-full px-3 py-2 border rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                {t.cart.form.additionalInfo}
              </label>
              <textarea
                value={formData.additionalInfo}
                onChange={(e) => setFormData({ ...formData, additionalInfo: e.target.value })}
                className="w-full px-3 py-2 border rounded-md h-32 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              />
            </div>
          </div>
          <div className="mt-6 flex justify-end space-x-4">
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="px-6 py-2 border rounded-lg text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 dark:border-gray-600 transition-colors"
              disabled={isSubmitting}
            >
              {t.cart.back}
            </button>
            <button
              type="submit"
              className="bg-primary-600 text-white px-6 py-2 rounded-lg hover:bg-primary-700 disabled:opacity-50 transition-colors"
              disabled={isSubmitting}
            >
              {isSubmitting ? t.cart.sending : t.cart.sendOrder}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}