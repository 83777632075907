import React from 'react';
import { Link } from 'react-router-dom';
import { Trees as Tree, Truck, Shovel, Phone, Leaf, ArrowRight } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTree } from '@fortawesome/free-solid-svg-icons';
import { useLanguageStore } from '../store';
import { translations } from '../translations';

export default function HomePage() {
  const language = useLanguageStore((state) => state.language);
  const t = translations[language];

  return (
    <div className="flex flex-col">
      {/* Hero Section */}
      <div className="relative min-h-screen">
        {/* Overlay gradient */}
        <div className="absolute inset-0 bg-gradient-to-b from-green-900/70 via-green-800/60 to-green-950/90 z-10" />
        
        {/* Background image */}
        <div 
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: 'url("https://images.unsplash.com/photo-1542273917363-3b1817f69a2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80")'
          }}
        />

        {/* Content */}
        <div className="relative z-20 max-w-7xl mx-auto px-4 h-screen flex items-center">
          <div className="max-w-3xl">
            <div className="inline-flex items-center bg-green-900/40 backdrop-blur-sm rounded-full px-4 py-2 mb-6">
              <Leaf className="w-5 h-5 text-green-400" />
              <span className="text-green-100">{t.home.hero.badge}</span>
            </div>
            <h1 className="text-5xl md:text-7xl font-bold mb-6 text-white leading-tight">
              {t.home.hero.title.line1} <br />
              <span className="text-green-400">{t.home.hero.title.line2}</span>
            </h1>
            <p className="text-xl md:text-2xl mb-8 text-gray-200 max-w-2xl">
              {t.home.hero.subtitle}
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <Link
                to="/katalog"
                className="inline-flex items-center justify-center bg-green-600 text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-green-700 transition-colors group"
              >
                {t.home.hero.catalogButton}
                <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
              </Link>
              <a
                href="tel:696022651"
                className="inline-flex items-center justify-center bg-white/10 backdrop-blur-sm text-white px-8 py-4 rounded-lg text-lg font-semibold hover:bg-white/20 transition-colors"
              >
                <Phone className="w-5 h-5 mr-2" />
                {t.home.hero.callButton}
              </a>
            </div>
          </div>
        </div>

        {/* Scroll indicator */}
        <div className="absolute bottom-8 left-1/2 -translate-x-1/2 z-20 animate-bounce hidden md:block">
          <div className="w-8 h-14 border-2 border-white/30 rounded-full flex items-start justify-center p-2">
            <div className="w-1 h-3 bg-white/60 rounded-full" />
          </div>
        </div>
      </div>

      {/* Features Section */}
      <section className="py-24 bg-green-950 dark:bg-gray-900 transition-colors duration-200">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
            <div className="group">
              <div className="w-16 h-16 bg-green-900/50 backdrop-blur-sm rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform">
                <FontAwesomeIcon icon={faTree} className="w-8 h-8 text-green-400" />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-white">{t.home.features.plants.title}</h3>
              <p className="text-gray-400">{t.home.features.plants.description}</p>
            </div>
            <div className="group">
              <div className="w-16 h-16 bg-green-900/50 backdrop-blur-sm rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform">
                <Truck className="w-8 h-8 text-green-400" />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-white">{t.home.features.transport.title}</h3>
              <p className="text-gray-400">{t.home.features.transport.description}</p>
            </div>
            <div className="group">
              <div className="w-16 h-16 bg-green-900/50 backdrop-blur-sm rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform">
                <Shovel className="w-8 h-8 text-green-400" />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-white">{t.home.features.design.title}</h3>
              <p className="text-gray-400">{t.home.features.design.description}</p>
            </div>
            <div className="group">
              <div className="w-16 h-16 bg-green-900/50 backdrop-blur-sm rounded-xl flex items-center justify-center mb-6 group-hover:scale-110 transition-transform">
                <Phone className="w-8 h-8 text-green-400" />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-white">{t.home.features.advice.title}</h3>
              <p className="text-gray-400">{t.home.features.advice.description}</p>
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section className="py-24 bg-white dark:bg-gray-800 relative overflow-hidden transition-colors duration-200">
        {/* Background pattern */}
        <div className="absolute inset-0 opacity-5">
          <div className="absolute inset-0" style={{
            backgroundImage: 'url("data:image/svg+xml,%3Csvg width=\'60\' height=\'60\' viewBox=\'0 0 60 60\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M54.627 0l.83.828-1.415 1.415L51.8 0h2.827zM5.373 0l-.83.828L5.96 2.243 8.2 0H5.374zM48.97 0l3.657 3.657-1.414 1.414L46.143 0h2.828zM11.03 0L7.372 3.657 8.787 5.07 13.857 0H11.03zm32.284 0L49.8 6.485 48.384 7.9l-7.9-7.9h2.83zM16.686 0L10.2 6.485 11.616 7.9l7.9-7.9h-2.83zM22.344 0L13.858 8.485 15.272 9.9l7.9-7.9h-.828zm5.656 0L19.515 8.485 17.343 10.657 28 0h-2.83zM32.656 0L26.172 6.485 24 8.657 34.657 0h-2zM44.97 0L40.5 4.472 36.03 0h8.94zM12.807 0L9.5 3.308 6.193 0h6.614zM48.743 0L42.5 6.243 36.257 0h12.486zM15.857 0L9.5 6.357 3.143 0h12.714L21 6.357 14.643 0h12.714L33.5 6.357 27.143 0h12.714L45 6.357 38.643 0h12.714L57.5 6.357 51.143 0h6.614z\' fill=\'%23ffffff\' fill-opacity=\'1\' fill-rule=\'evenodd\'/%3E%3C/svg%3E")',
            backgroundSize: '24px'
          }} />
        </div>
        
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div className="relative">
              <div className="absolute -inset-4 bg-green-500/10 rounded-2xl transform -rotate-6" />
              <div className="relative h-[500px] rounded-xl overflow-hidden">
                <img
                  src="https://krzewywojcik.com.pl/wp-content/uploads/2024/11/xDJI_0222-1024x511.jpg.pagespeed.ic.XbI4u8Y1ig.webp"
                  alt={language === 'pl' ? 'Szkółka roślin Iglak Mierzejewski - widok z lotu ptaka' : 'Iglak Mierzejewski Plant Nursery - aerial view'}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div>
              <h2 className="text-4xl font-bold mb-8 text-green-950 dark:text-green-400 flex items-center">
                <FontAwesomeIcon icon={faTree} className="text-green-600 dark:text-green-400 h-8 w-8 mr-3" />
                {t.home.about.title}
              </h2>
              <div className="space-y-6 text-gray-700 dark:text-gray-300">
                {t.home.about.description.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}