import React from 'react';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { Eye, Trash2 } from 'lucide-react';
import { OrderWithItems } from '../../types';

interface OrderListProps {
  orders: OrderWithItems[];
  onViewOrder: (order: OrderWithItems) => void;
  onDeleteOrder: (orderId: string) => void;
  onStatusChange: (orderId: string, status: OrderWithItems['status']) => void;
}

const getStatusLabel = (status: OrderWithItems['status']) => {
  switch (status) {
    case 'pending': return 'Oczekujące';
    case 'confirmed': return 'Potwierdzone';
    case 'completed': return 'Zrealizowane';
    case 'cancelled': return 'Anulowane';
    default: return status;
  }
};

const getStatusStyles = (status: OrderWithItems['status']) => {
  switch (status) {
    case 'pending': return 'bg-yellow-900/50 text-yellow-200';
    case 'confirmed': return 'bg-blue-900/50 text-blue-200';
    case 'completed': return 'bg-green-900/50 text-green-200';
    case 'cancelled': return 'bg-red-900/50 text-red-200';
    default: return '';
  }
};

export default function OrderList({ orders, onViewOrder, onDeleteOrder, onStatusChange }: OrderListProps) {
  return (
    <div className="bg-gray-800 rounded-lg shadow overflow-hidden">
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-700">
          <thead className="bg-gray-700">
            <tr>
              <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Data
              </th>
              <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Klient
              </th>
              <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Kontakt
              </th>
              <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Dostawa
              </th>
              <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Status
              </th>
              <th scope="col" className="px-4 sm:px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Suma
              </th>
              <th scope="col" className="relative px-4 sm:px-6 py-3">
                <span className="sr-only">Akcje</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-800 divide-y divide-gray-700">
            {orders.map((order) => (
              <tr key={order.id} className="hover:bg-gray-700/50">
                <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {format(new Date(order.created_at), 'dd.MM.yyyy HH:mm', { locale: pl })}
                </td>
                <td className="px-4 sm:px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-100">
                    {order.first_name} {order.last_name}
                  </div>
                  {order.address && (
                    <div className="text-sm text-gray-400 mt-1">
                      {order.address}
                    </div>
                  )}
                </td>
                <td className="px-4 sm:px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-100">{order.phone}</div>
                  <div className="text-sm text-gray-400">{order.email}</div>
                </td>
                <td className="px-4 sm:px-6 py-4 whitespace-nowrap">
                  <div className="text-sm text-gray-100">
                    {format(new Date(order.delivery_date), 'dd.MM.yyyy', { locale: pl })}
                  </div>
                  <div className="flex flex-wrap gap-2 mt-1">
                    {order.requires_transport && (
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-900/50 text-blue-200">
                        Transport
                      </span>
                    )}
                    {order.requires_planting && (
                      <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-900/50 text-green-200">
                        Sadzenie
                      </span>
                    )}
                  </div>
                </td>
                <td className="px-4 sm:px-6 py-4 whitespace-nowrap">
                  <select
                    value={order.status}
                    onChange={(e) => onStatusChange(order.id, e.target.value as OrderWithItems['status'])}
                    className={`rounded-full px-3 py-1 text-xs font-semibold bg-gray-700 border border-gray-600 ${getStatusStyles(order.status)}`}
                  >
                    <option value="pending">Oczekujące</option>
                    <option value="confirmed">Potwierdzone</option>
                    <option value="completed">Zrealizowane</option>
                    <option value="cancelled">Anulowane</option>
                  </select>
                </td>
                <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-100">
                  {order.total_amount.toFixed(2)} zł
                </td>
                <td className="px-4 sm:px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <div className="flex items-center justify-end space-x-2">
                    <button
                      onClick={() => onViewOrder(order)}
                      className="text-primary-400 hover:text-primary-300"
                      title="Szczegóły"
                    >
                      <Eye className="h-5 w-5" />
                    </button>
                    {(order.status === 'completed' || order.status === 'cancelled') && (
                      <button
                        onClick={() => onDeleteOrder(order.id)}
                        className="text-red-400 hover:text-red-300"
                        title="Usuń"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}