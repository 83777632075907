import React from 'react';
import { useLanguageStore, useCartStore } from '../store';

export default function LanguageSwitcher() {
  const { language, setLanguage } = useLanguageStore();
  const updateItemNames = useCartStore((state) => state.updateItemNames);
  const isPolish = language === 'pl';

  const handleLanguageChange = (newLanguage: 'pl' | 'en') => {
    setLanguage(newLanguage);
    updateItemNames(newLanguage);
  };

  return (
    <button
      onClick={() => handleLanguageChange(isPolish ? 'en' : 'pl')}
      className="flex items-center space-x-1 px-2 py-1 rounded hover:bg-primary-100 dark:hover:bg-primary-900"
      title={isPolish ? 'Switch to English' : 'Przełącz na Polski'}
    >
      <img
        src={`https://flagcdn.com/w20/${isPolish ? 'gb' : 'pl'}.png`}
        width="20"
        height="15"
        alt={isPolish ? 'British flag' : 'Polish flag'}
        className="rounded"
      />
      <span className="text-sm">{isPolish ? 'EN' : 'PL'}</span>
    </button>
  );
}