import { create } from 'zustand';
import { CartItem } from './types';
import { persist } from 'zustand/middleware';
import { supabase } from './lib/supabase';

interface CartStore {
  items: CartItem[];
  addItem: (item: CartItem) => void;
  removeItem: (plantId: string, height: string) => void;
  updateQuantity: (plantId: string, height: string, quantity: number) => void;
  clearCart: () => void;
  updateItemNames: (language: 'pl' | 'en') => void;
}

interface ThemeStore {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}

interface LanguageStore {
  language: 'pl' | 'en';
  setLanguage: (lang: 'pl' | 'en') => void;
}

export const useCartStore = create(
  persist<CartStore>(
    (set, get) => ({
      items: [],
      addItem: (item) =>
        set((state) => {
          // Validate item before adding
          if (!item.selectedSize || typeof item.selectedSize.price !== 'number' || !item.quantity) {
            return state;
          }

          const existingItem = state.items.find(
            (i) => i.plantId === item.plantId && i.selectedSize.height === item.selectedSize.height
          );

          if (existingItem) {
            return {
              items: state.items.map((i) =>
                i.plantId === item.plantId && i.selectedSize.height === item.selectedSize.height
                  ? { ...i, quantity: i.quantity + item.quantity }
                  : i
              ),
            };
          }

          return { items: [...state.items, item] };
        }),
      removeItem: (plantId, height) =>
        set((state) => ({
          items: state.items.filter(
            (item) => !(item.plantId === plantId && item.selectedSize.height === height)
          ),
        })),
      updateQuantity: (plantId, height, quantity) =>
        set((state) => {
          if (quantity <= 0) {
            return {
              items: state.items.filter(
                (item) => !(item.plantId === plantId && item.selectedSize.height === height)
              ),
            };
          }
          return {
            items: state.items.map((item) =>
              item.plantId === plantId && item.selectedSize.height === height
                ? { ...item, quantity }
                : item
            ),
          };
        }),
      clearCart: () => set({ items: [] }),
      updateItemNames: async (language) => {
        const currentItems = get().items;
        if (currentItems.length === 0) return;

        const plantIds = currentItems.map(item => item.plantId);
        
        const { data: plants } = await supabase
          .from('plants')
          .select('id, name, name_en')
          .in('id', plantIds);

        if (plants) {
          set((state) => ({
            items: state.items.map(item => {
              const plant = plants.find(p => p.id === item.plantId);
              return plant ? {
                ...item,
                plantName: language === 'en' ? plant.name_en || plant.name : plant.name
              } : item;
            })
          }));
        }
      }
    }),
    {
      name: 'cart-storage',
      version: 1,
      migrate: (persistedState: any, version: number) => {
        if (version === 0) {
          // Migration from version 0 to 1
          return {
            items: Array.isArray(persistedState.items) 
              ? persistedState.items.filter((item: any) => 
                  item && 
                  item.selectedSize && 
                  typeof item.selectedSize.price === 'number' && 
                  item.quantity > 0
                )
              : []
          };
        }
        return persistedState as CartStore;
      },
      partialize: (state) => ({ 
        items: state.items.filter(item => 
          item && 
          item.selectedSize && 
          typeof item.selectedSize.price === 'number' && 
          item.quantity > 0
        ) 
      })
    }
  )
);

export const useThemeStore = create(
  persist<ThemeStore>(
    (set) => ({
      isDarkMode: true,
      toggleDarkMode: () => set((state) => ({ isDarkMode: !state.isDarkMode })),
    }),
    {
      name: 'theme-storage',
      version: 1,
      migrate: (persistedState: any) => {
        return {
          isDarkMode: typeof persistedState.isDarkMode === 'boolean' 
            ? persistedState.isDarkMode 
            : true
        };
      }
    }
  )
);

export const useLanguageStore = create(
  persist<LanguageStore>(
    (set) => ({
      language: 'pl',
      setLanguage: (language) => set({ language }),
    }),
    {
      name: 'language-storage',
      version: 1,
      migrate: (persistedState: any) => {
        return {
          language: persistedState.language === 'en' ? 'en' : 'pl'
        };
      }
    }
  )
);