import React from 'react';
import { ExternalLink } from 'lucide-react';
import { useLanguageStore } from '../store';
import { translations } from '../translations';
import ImageLoader from '../components/ImageLoader';

export default function GalleryPage() {
  const language = useLanguageStore((state) => state.language);
  const t = translations[language];

  const images = [
    'https://i.postimg.cc/3N3SrVPX/20231230-102943.webp',
    'https://i.postimg.cc/FssWMtbm/20231230-125907.webp',
    'https://i.postimg.cc/XvyDswfC/20231230-103018.webp',
    'https://i.postimg.cc/WbWWj9SZ/20231230-130931.webp',
    'https://i.postimg.cc/nz4SJpwV/20240511-190457.webp',
    'https://i.postimg.cc/fWvqqW9B/20231127-152026.webp',
    'https://i.postimg.cc/NFN4sM8t/20220527-065536.webp',
    'https://i.postimg.cc/h4MMbCkY/20220527-111914.webp',
    'https://i.postimg.cc/RZJRmvWr/20220511-100622.webp',
    'https://i.postimg.cc/Prxy6Wh5/20220511-134059.webp',
    'https://i.postimg.cc/GpJv3trf/20211112-113613.webp',
    'https://i.postimg.cc/dtxBghtm/20220507-152550.webp',
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8 dark:text-white">{t.gallery.title}</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {images.map((image, index) => (
          <div key={index} className="aspect-square overflow-hidden rounded-lg shadow-lg">
            <ImageLoader
              src={`${image}?auto=format&fit=crop&w=800&q=80`}
              alt={`${t.gallery.title} ${index + 1}`}
              className="w-full h-full hover:scale-105 transition-transform duration-300"
            />
          </div>
        ))}
      </div>
      
      <div className="flex justify-center mt-12">
        <a 
          href="https://postimg.cc/gallery/wsbKdyT" 
          target="_blank" 
          rel="noopener noreferrer"
          className="inline-flex items-center px-6 py-3 bg-primary-600 text-white font-medium rounded-lg shadow-md hover:bg-primary-700 transition-colors duration-200"
        >
          {t.gallery.viewAll}
          <ExternalLink className="ml-2 h-5 w-5" />
        </a>
      </div>
    </div>
  );
}