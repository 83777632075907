import React from 'react';
import { format } from 'date-fns';
import { pl } from 'date-fns/locale';
import { X } from 'lucide-react';
import { OrderWithItems } from '../../types';

interface OrderDetailsProps {
  order: OrderWithItems;
  onClose: () => void;
}

export default function OrderDetails({ order, onClose }: OrderDetailsProps) {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-gray-800 rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="px-6 py-4 border-b border-gray-700">
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-medium text-gray-100">
              Szczegóły zamówienia
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-300"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>
        <div className="px-6 py-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
            <div>
              <h4 className="text-sm font-medium text-gray-400">Klient</h4>
              <p className="mt-1 text-gray-100">
                {order.first_name} {order.last_name}
              </p>
              <p className="text-sm text-gray-400">{order.email}</p>
              <p className="text-sm text-gray-400">{order.phone}</p>
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-400">Dostawa</h4>
              <p className="mt-1 text-gray-100">
                {format(new Date(order.delivery_date), 'dd.MM.yyyy', { locale: pl })}
              </p>
              {order.address && (
                <p className="text-sm text-gray-400">{order.address}</p>
              )}
            </div>
          </div>

          <div className="border-t border-gray-700 pt-4">
            <h4 className="text-sm font-medium text-gray-400 mb-4">Zamówione rośliny</h4>
            <div className="space-y-4">
              {order.items.map((item, index) => (
                <div key={index} className="flex justify-between items-center">
                  <div>
                    <p className="font-medium text-gray-100">{item.plant_name}</p>
                    <p className="text-sm text-gray-400">
                      Wysokość: {item.height} | Ilość: {item.quantity}
                    </p>
                  </div>
                  <p className="font-medium text-gray-100">
                    {(item.price * item.quantity).toFixed(2)} zł
                  </p>
                </div>
              ))}
            </div>
          </div>

          {order.additional_info && (
            <div className="border-t border-gray-700 mt-4 pt-4">
              <h4 className="text-sm font-medium text-gray-400 mb-2">
                Dodatkowe informacje
              </h4>
              <p className="text-sm text-gray-300">{order.additional_info}</p>
            </div>
          )}

          <div className="border-t border-gray-700 mt-4 pt-4">
            <div className="flex justify-between items-center">
              <h4 className="text-lg font-medium text-gray-100">Razem</h4>
              <p className="text-lg font-bold text-gray-100">
                {order.total_amount.toFixed(2)} zł
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}